import React from 'react';

import P5Wrapper from 'react-p5-wrapper';
import GOL from './sketches/GOL';

const Sketch = () => (
  <div className="GOL" style={{minHeight:"40vh"}}>
    <div>
      {typeof window !== 'undefined'
        ? <P5Wrapper sketch={GOL} />
        : null}

    </div>
  </div>
);
export default Sketch;
